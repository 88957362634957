<template>
  <div class="clearfix home_login">
    <!-- <p class="float_right" v-if="!isLogin"><router-link to="/login" >登 录</router-link></p> -->
    <p class="float_right" v-if="!isLogin"><router-link to="/login" >登 录</router-link></p>
    <p class="float_right" v-else>欢迎：<a href="/manage" >{{ uname }}</a></p>
  </div>  
</template>

<script>
  import axios from 'axios';
  export default {
    name:"HomeLogin",
    data(){
      return {
        isLogin:false,
        uname:"",
      }
    },
    mounted(){
      axios.post("/testLogin").then((res) => {
        if(res.data.state){
          // 如果已经登录
          this.uname = res.data.uname;
          this.isLogin = true;
        }else{
          // 如果没有登录
          this.isLogin = false;
        }
      });
    },
  }
</script>

<style lang="less" scoped>
    .home_login{
      width:100%;
      height:25px;
      line-height:25px;
      p{
        font-size:14px;
        color:#000;
        a{
        font-family: "Microsoft YaHei";
        padding-right:20px;
        font-size:14px;
        color:#000;
        margin:0;
        }
      }
    }
</style>