import Vue from 'vue'
import VueRouter from 'vue-router'
import router from './router'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueScrollmagic from "vue-scrollmagic";
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(ElementUI);
Vue.use(VueScrollmagic, {
  verical: true,
  globalSceneOptions: {
    triggerHook: "onLeave"
    // duration: "100%"
  },
  loglevel: 2,
  refreshInterval: 100
});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
