<template>
  <div class="home_page">
      <HomeLogin></HomeLogin>
    <div class="home_box">
      <div class="home_hello clearfix">
        <div class="home_title float_left">
          <h1>你好,</h1>
          <h1>我是朱利强，</h1>
          <h1>一个旅行者。</h1>
          <h2>爱好旅行、电影、阅读和编程。</h2>
        </div>
        <div class="home_img float_left">
          <el-carousel :interval="4000"  height="500px" trigger="click" indicator-position = none>
            <el-carousel-item v-for="item in imgUrl" :key="item">
              <img :src="item" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="home_content">
        <div class="paragraph">
          <p class="first_p">我对电影和旅行怀有浓厚的兴趣。</p>
          <p>我热衷于技术的前沿，</p>
          <p>除了书籍，也喜欢从大自然和互联网获取自己感兴趣的知识。</p>
          <div class="to_top">
            <p class="trends_margin">旅行是从大自然学习的最佳方式</p>
            <p>走遍世界，对我而言不仅仅是梦想。</p>
          </div>
        </div>
        <MyParameter id="container"></MyParameter>
        <div class="paragraph to_po">
          <p>旅行是心灵的探索；</p>
          <p>而程序则是世界的编码。</p>
        </div>
        <div class="rotate_img">
          <ul class="clearfix ul_rotate">
            <li class="float_left"><img src="../assets/img/6668561243.jpg" alt=""></li>
            <li class="float_left"><img src="../assets/img/8458321324.jpg" alt=""></li>
            <li class="float_left"><img src="../assets/img/956343212143.jpg" alt=""></li>
            <li class="float_left"><img src="../assets/img/543271244.jpg" alt=""></li>
            <li class="float_left"><img src="../assets/img/262738747.jpg" alt=""></li>
            <li class="float_left"><img src="../assets/img/23473456.jpg" alt=""></li>
            <li class="float_left"><img src="../assets/img/213463412346.jpg" alt=""></li>
            <li class="float_left"><img src="../assets/img/8345723412.jpg" alt=""></li>
          </ul>
        </div>      
        <div class="to_mar">
          <div class="paragraph">
            <p>作为一个追求多元的旅者，</p>
            <p>我的爱好让我更加深入地理解世界。</p>
          </div>
        </div>
        <Graph></Graph>
        <div class="top_mar1">
          <div class="paragraph">
            <p>编程不仅是一种技能，更是一种思维方式，</p>
          </div>
        </div>
        <div class="top_mar2">
          <div class="paragraph">
            <p>它能帮我更好地理解和塑造这个世界。</p>
          </div>
        </div>
        <div class="blackEnding">
          <div class="clearfix blackMargin">
            <el-row>
              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div class="conclusion float_left">
                <div class="web_logo">
                  <span class="aite iconfont icon-aite"></span>
                  <p>zhuliqiang<span>.com</span></p>
                </div>
                <div class="text">
                  <p  class="m_t_25">你正在浏览的是我<span class="t_a">开发</span>的个人<span class="t_b">网站</span></p>
                  <p>如果你觉得还不错请为我<span class="t_c">点赞</span></p>
                  <p>我会不断提高自己的<span class="t_d">编程</span>技能</p>
                  <p class="m_t_25">当然，更欢迎你有好的创意或想法给我<span class="t_e">留言</span></p>
                  <p>我会继续<span class="t_b">探索</span>世界、记录生活、<span class="t_f">创造</span>故事</p>
                </div>
                <div class="like clearfix" @click="addLick">
                  <span  :class="iconLick" class="float_left iconfont icon-icon like_s1 "></span>
                  <div class="float_left">
                    <p class="like_p1">为网站点赞</p>
                    <p class="lick_p2">{{ lickValue }}</p>
                  </div>
                </div>
              </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div class="hid">
                <LeaveMessage id="LeaveMessage"></LeaveMessage>
              </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import 'animate.css';
  import MyParameter from "../components/MyParameter"
  import Graph from "../components/Graph"
  import LeaveMessage from "../components/LeaveMessage"
  import HomeLogin from "../components/HomeLogin"
  export default {
    name: 'Home',
    components:{MyParameter,Graph,LeaveMessage,HomeLogin},
    data(){
      return{
        imgUrl:[
          require("../assets/img/2023957123.jpg"),
          require("../assets/img/2663237.jpg"),
          require("../assets/img/1684325.jpg"),
        ],
        iconLick:"",
        lickValue:"",
        isReverse:true,
        scenes:[
          {
            scene:{
              triggerElement: "body", // 触发元素
              triggerHook: 0,// 定义触发器 Hook 相对于视口的位置 没弄明白
              reverse: true, // 控制回滚时、是否加载动画
              offset: 0, // 偏量、动画开始位置的微调、也可当作triggerElement使用、当triggerElement不存在的时候。
              duration: "20%" // 动画持续进度
            },
            tweenElement: ".home_title", // 变化元素
            tween: { // 变更动画内容
              css: { // 配置最终css
                // transform:translateX(15) // 样式参数
                transform:"translateY(-150px)",
                opacity:0.2
              },
            },  
          },
          {
            scene:{
              triggerElement: "body", // 触发元素
              triggerHook: 0,// 定义触发器 Hook 相对于视口的位置 没弄明白
              reverse: true, // 控制回滚时、是否加载动画
              offset: 0, // 偏量、动画开始位置的微调、也可当作triggerElement使用、当triggerElement不存在的时候。
              duration: "20%" // 动画持续进度
            },
            tweenElement: ".home_img", // 变化元素
            tween: { // 变更动画内容
              css: { // 配置最终css
                // transform:translateX(15) // 样式参数
                transform:"translateY(-350px)",
                opacity:0.1
              },
            },
          },
          {
            scene:{
              triggerElement: "body", // 触发元素
              triggerHook: 0,// 定义触发器 Hook 相对于视口的位置 没弄明白
              reverse: true, // 控制回滚时、是否加载动画
              offset: 400, // 偏量、动画开始位置的微调、也可当作triggerElement使用、当triggerElement不存在的时候。
              duration: "70%" // 动画持续进度
            },
            tweenElement: "#container", // 变化元素
            tween: { // 变更动画内容
              css: { // 配置最终css
                // transform:translateX(15) // 样式参数
                transform:"translateY(0px)",
                transform:"scale(1)"
              },
            },
          },
          {
            scene:{
              triggerElement: "body", // 触发元素
              triggerHook: 0,// 定义触发器 Hook 相对于视口的位置 没弄明白
              reverse: true, // 控制回滚时、是否加载动画
              offset: 400, // 偏量、动画开始位置的微调、也可当作triggerElement使用、当triggerElement不存在的时候。
              duration: "70%" // 动画持续进度
            },
            tweenElement: ".to_top", // 变化元素
            tween: { // 变更动画内容
              css: { // 配置最终css
                // transform:translateX(15) // 样式参数
                transform:"translateY(0px)",
                opacity:1,
              },
            },
          },
          {
            scene:{
              triggerElement: "body", // 触发元素
              triggerHook: 0,// 定义触发器 Hook 相对于视口的位置 没弄明白
              reverse: true, // 控制回滚时、是否加载动画
              offset: 1400, // 偏量、动画开始位置的微调、也可当作triggerElement使用、当triggerElement不存在的时候。
              duration: "130%" // 动画持续进度
            },
            tweenElement: ".ul_rotate", // 变化元素
            tween: { // 变更动画内容
              css: { // 配置最终css
                transform: "rotateX(0deg) rotateY(0deg) rotateZ(0deg)"
              },
            },
          },
          {
            scene:{
              triggerElement: "body", // 触发元素
              triggerHook: 0,// 定义触发器 Hook 相对于视口的位置 没弄明白
              reverse: true, // 控制回滚时、是否加载动画
              offset: 3700, // 偏量、动画开始位置的微调、也可当作triggerElement使用、当triggerElement不存在的时候。
              duration: "50%" // 动画持续进度
            },
            tweenElement: ".top_mar1", // 变化元素
            tween: { // 变更动画内容
              css: { // 配置最终css
                opacity: 0,
              },
            },
          },
          {
            scene:{
              triggerElement: "body", // 触发元素
              triggerHook: 0,// 定义触发器 Hook 相对于视口的位置 没弄明白
              reverse: true, // 控制回滚时、是否加载动画
              offset: 3700, // 偏量、动画开始位置的微调、也可当作triggerElement使用、当triggerElement不存在的时候。
              duration: "50%" // 动画持续进度
            },
            tweenElement: ".top_mar2", // 变化元素
            tween: { // 变更动画内容
              css: { // 配置最终css
                opacity: 1,
              },
            },
          },
          {
            scene:{
              triggerElement: "body", // 触发元素
              triggerHook: 0,// 定义触发器 Hook 相对于视口的位置 没弄明白
              reverse: false, // 控制回滚时、是否加载动画
              offset: 4200, // 偏量、动画开始位置的微调、也可当作triggerElement使用、当triggerElement不存在的时候。
              duration: "45%" // 动画持续进度
            },
            tweenElement: ".conclusion", // 变化元素
            tween: { // 变更动画内容
              css: { // 配置最终css
                transform:"translate(650px, 0px)"
              },
            },
          },
          {
            scene:{
              triggerElement: "body", // 触发元素
              triggerHook: 0,// 定义触发器 Hook 相对于视口的位置 没弄明白
              reverse: false, // 控制回滚时、是否加载动画
              offset: 4500, // 偏量、动画开始位置的微调、也可当作triggerElement使用、当triggerElement不存在的时候。
              duration: "1%" // 动画持续进度
            },
            tweenElement: ".like", // 变化元素
            tween: { // 变更动画内容
              css: { // 配置最终css
                opacity:1,
              },
            },
          },
          {
            scene:{
              triggerElement: "body", // 触发元素
              triggerHook: 0,// 定义触发器 Hook 相对于视口的位置 没弄明白
              reverse: false, // 控制回滚时、是否加载动画
              offset: 4600, // 偏量、动画开始位置的微调、也可当作triggerElement使用、当triggerElement不存在的时候。
              duration: "0%" // 动画持续进度
            },
            tweenElement: ".conclusion", // 变化元素
            tween: { // 变更动画内容
              css: { // 配置最终css
                transform:"translate(0px, 0px)"
              },
            },
          },
          {
            scene:{
              triggerElement: "body", // 触发元素
              triggerHook: 0,// 定义触发器 Hook 相对于视口的位置 没弄明白
              reverse: false, // 控制回滚时、是否加载动画
              offset: 4600, // 偏量、动画开始位置的微调、也可当作triggerElement使用、当triggerElement不存在的时候。
              duration: "0%" // 动画持续进度
            },
            tweenElement: "#LeaveMessage", // 变化元素
            tween: { // 变更动画内容
              css: { // 配置最终css
                // opacity: 0;
                transform:"translateY(0px)"
              },
            },
          },
        ],
      };
    },
    methods:{
      addLick(){
        const isLick = sessionStorage.getItem("isLick");
        if(!isLick){
          // 如果缓存中没有
          this.iconLick = "animate__animated animate__heartBeat";
          sessionStorage.setItem("isLick","true");
          // 发送axios请求，添加数据
          axios.post("/upLike").then((res) => {
            if(res.data == true){
              this.lickValue ++;
            }
          });
        }else{
          this.$message({
            message: '您已经点过赞了',
            type: 'success'
          });
        }
      }
    },
    mounted() {
      this.$message({
          showClose: true,
          type: 'warning',
          message: '本网站未适配移动端，PC端食用效果更佳~'
        });
      this.scenes.map((screne, i) => {
        const scene = this.$scrollmagic.scene(screne.scene)
        .setTween(screne.tweenElement, screne.tween)
        // .addIndicators({ name: `Scene ${i}` });
        this.$scrollmagic.addScene(scene);
      });
      // 获取点赞数量
      axios.post("/queryLike").then((res) =>{
        this.lickValue = res.data;
      });
    },
  }
</script>

<style lang="less" scoped>
  @media screen and (min-width:768px) and (max-width:991px){
    .home_img{
      margin:0 auto;
      width:100vw !important;
      img{
        width:100vw!important;
      }
    }
    .rotate_img{
      width:100vw !important;
      img{
        width:35vw !important;
      }
    }
  }
  @media screen and (max-width:767px){
    .home_img{
      margin:0 auto;
      width:100vw!important;
      img{
        width:100vw!important;
      }
    }
    .rotate_img{
      width:100vw !important;
      img{
        width:35vw !important;
      }
    }
  }
  .hid{
    overflow: hidden;
  }
  #LeaveMessage{
    // opacity: 0;
    transform:translateY(600px);
  }
  #container{
    transform:translateY(-350px);
    transform:scale(2.5)
  }
  .top_mar1{
    margin:200px 0 150px 0;
  }
  .top_mar2{
    opacity: 0;
    margin-bottom: 400px;
  }
  .to_mar{
    margin:200px 0 50px 0;
  }
  .ul_rotate{
    transform: rotateX(60deg) rotateY(0deg) rotateZ(60deg);
  }
  .to_po{
    margin:200px auto!important;
  }
  .to_top{
    margin-top:150px;
    opacity:0;
    transform:translateY(-50px);
  }
  .home_page{
    overflow: hidden;
    background-color: #FDFDFD;
    font-family: "plantc", "Source Han Serif", serif;
    color:#404040;
  }
  .home_box{
    .home_hello{
      max-width:1200px;
      margin:0 auto;
      margin-bottom: 120px;
      .home_title{
        
        padding:180px 0;
        margin-right:10px;
        h1{
          font-size:66px;
          line-height:1.2;  
        }
        h2{          
          margin-top:10px;
          font-size:26px;
        }
      }
      .home_img{
        width:790px;
        padding-top:90px;
        img{
          // box-shadow: 0 20px 90px 0 rgba(0, 0, 0, .08), 0 9px 30px 0 rgba(0, 0, 0, .1);
          width:790px;
          // height:500px;
        }
      }
    }
  }
  .home_content{
    .paragraph{
      max-width:1200px;
      margin:0 auto;
      text-align: center;
      font-size:30px;
      font-weight: 600;
      p{
        line-height:1.3;
      }
      .first_p{
        margin-bottom: 30px;
      }
      .trends_margin{
        margin-top:60px;
      }
    }
    .rotate_img{
      width:640px;
      margin:0 auto;
      li{
        border:10px solid #fff;
        box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18);
        margin:0 20px 20px 0;
        img{
        width:280px;
        height:200px;
      }
      }
    }
    .blackEnding{
      width:100%;
      padding:100px 0 100px 0;
      background: #000;
      .blackMargin{
        max-width:1200px;
        margin:0 auto;
      }
      @media (max-width: 1200px){
      .conclusion {
            margin-bottom: 50px;
        }
      }
      .conclusion{
        transform:translate(650px,-500px);
        color:#fdfdfd;
        max-width:500px;
        .web_logo{
          position:relative;
          height:80px;
          line-height:80px;
          padding-left:90px;
          .aite{
            position:absolute;
            top:0;
            left:0;
            font-size:80px;
          }
          p{
            font-size:42px;
            span{
              font-size:24px;
            }
          }
        }
        .text{
          font-size:28px;
          span{
            font-weight: 600;
            color: transparent;
            -webkit-background-clip: text;
            background-clip: text;
          }
          .t_a{
            background-image: linear-gradient(to right, #24c6dc, #514a9d);
          }
          .t_b{
            background-image: linear-gradient(to right, #f3cd13, #f35858);
          }
          .t_c{
            background-image: linear-gradient(to right, #dd5e89, #f7bb97);
          }
          .t_d{
            background-image: linear-gradient(to right, #3ca55c, #b5ac49);
          }
          .t_e{
            background-image: linear-gradient(to right, #4cb8c4, #3cd3ad);
          }
          .t_f{
            background-image: linear-gradient(to right, #4776e6, #8e54e9);
          }
          p{
            font-weight: 600;
            line-height:1.3;
          }
          .m_t_25{
            margin-top: 25px;
          }
          .m_b_25{
            margin-bottom: 25px;
          }
        }
        .like{
          margin-top:15px;
          opacity: 0;
          position:relative;
          box-sizing: border-box;
          cursor: pointer;
          width:210px;
          height:70px;
          background-image: linear-gradient(to right, #c04848 0%, #480048);
          border-radius: 10px;
          transition: .3s ease-out;
          font-weight: 600;
          padding:15px 10px 10px 25px;
          &:hover{
            box-shadow: 0 6px 90px 0 #c04848, 0 8px 60px 0 #480048;
            transform: scale(1.1);
          }
          p{
            font-size:20px;
            margin-bottom: 5px;
          }
          .like_s1{
            font-size:40px;
            margin-right:20px;
          }
        }
      }
    }
  }
</style>
