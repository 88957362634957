<template>
  <div class="grey">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>登录</span>
      </div>
      <el-form label-width="65px" :model="loginForm" :rules="loginRules" ref="loginForm">
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="loginForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="userPassword">
          <el-input type="password" v-model="loginForm.userPassword" @keyup.enter.native="userLogin('loginForm')"></el-input>
        </el-form-item>
        <div class="center_login">
          <el-button type="primary" @click="userLogin('loginForm')">登录</el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import axios from 'axios';
  import CryptoJS from 'crypto-js';
  export default {
    name:"Login",
    data(){
      return {
        loginForm:{
          userName:"",
          userPassword:"",
        },
        loginRules:{
          userName:[
            { required: true, message: '请输入用户名', trigger: 'blur' },
            { min: 5, max: 16, message: '长度在 5 到 16 个字符', trigger: 'blur' }
          ],
          userPassword:[
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
          ],
        },
      };
    },
    methods:{
      userLogin(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const key = CryptoJS.enc.Utf8.parse('0123456789abcdef'); // 密钥  
            const iv = CryptoJS.enc.Utf8.parse('0123456789abcdef'); // 初始向量 
            const userPassword = CryptoJS.AES.encrypt(this.loginForm.userPassword, key, { iv: iv }).toString(); // 加密数据
            const userInfo = {userName:this.loginForm.userName,userPassword};
            axios.post("/userLogin",userInfo).then((res) => {
              if(res.data){
                  this.$message({
                    showClose: true,
                    message: '登录成功！',
                    type: 'success'
                  });
                  this.loginForm.userName = "";
                  this.loginForm.userPassword = "";
                  window.location.href = "/manage";
                }else{
                  this.$message({
                    showClose: true,
                    message: '登录失败，请检查用户名或密码',
                    type: 'error'
                  });
                }
            });
          } else {
            this.$message.error('请输入完整的用户名和密码才可以登录');
            return false;
          }
        });
      }
    },
  }
</script>

<style lang="less" scoped>
  .grey{
    width:100vw;
    height:100vh;
    background-color: #fdfdfd;
    color:#404040;
    position:relative;
  }
  .box-card{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:480px;
    .center_login{
      text-align: center;
    }
  }
</style>