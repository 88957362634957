<template>
	<div class="container">
		<!--	圆心-->
		<div class="circle">我的生活</div>
		<!--	第一层-->
		<div class="borther">
			<div id="lopp"></div>
			<div class="ball" @mouseenter="onMouseGo()" @mouseout="onMouseMove()">编程</div>
			<div class="ball" @mouseenter="onMouseGo()" @mouseout="onMouseMove()">健身</div>
			<div class="ball" @mouseenter="onMouseGo()" @mouseout="onMouseMove()">烹饪</div>
			<div class="ball" @mouseenter="onMouseGo()" @mouseout="onMouseMove()">露营</div>
			<div class="ball" @mouseenter="onMouseGo()" @mouseout="onMouseMove()">跑步</div>
		</div>
		<!--	第二层-->
		<div class="borther2">
			<div id="lopp2"></div>
			<div class="ball ball2" @mouseenter="onMouseGo()" @mouseout="onMouseMove()">旅行</div>
			<div class="ball ball2" @mouseenter="onMouseGo()" @mouseout="onMouseMove()">电影</div>
			<div class="ball ball2" @mouseenter="onMouseGo()" @mouseout="onMouseMove()">摄影</div>
			<div class="ball ball2" @mouseenter="onMouseGo()" @mouseout="onMouseMove()">羽毛球</div>
			<div class="ball ball2" @mouseenter="onMouseGo()" @mouseout="onMouseMove()">阅读</div>
		</div>
	</div>
</template>

<script>
  export default {
    name:"MyParameter",
    data(){
      return {
        firstLayer:[1,2,3,4,5],
        firstFloor:[1,2,3,4,5],
      };
    },
    methods:{
      onMouseGo(){
        var items = document.querySelectorAll('.ball');
        // items[0].style['animation-delay'] = '-5s,0s,0s';
        // items[0].querySelector('.item__circle').style['transform'] = 'scale(1.5)';
        //
        for (var i = 0; i < items.length; i++) {
          (function (i) {
            items[i].style['animation-delay'] = (-5) - 4 * i + 's,' + (-4 * i) + 's,' + (-4 * i) + 's'
          })(i);
        }
        for (var i = 0; i < items.length; i++) {
          (function (i) {
            items[i].classList.add('active')
          })(i);
        }
      },
      onMouseMove(){
        var items = document.querySelectorAll('.ball');
        // items[0].style['animation-delay'] = '-5s,0s,0s';
        // items[0].querySelector('.item__circle').style['transform'] = 'scale(1.5)';
        //
        for (var i = 0; i < items.length; i++) {
          (function (i) {
            items[i].style['animation-delay'] = (-5) - 4 * i + 's,' + (-4 * i) + 's,' + (-4 * i) + 's'
          })(i);
        }
        for (var i = 0; i < items.length; i++) {
          (function (i) {
            items[i].classList.remove('active')
          })(i);
        }
      },
    },
    mounted(){
      var items = document.querySelectorAll('.ball');
      // items[0].style['animation-delay'] = '-5s,0s,0s';
      // items[0].querySelector('.item__circle').style['transform'] = 'scale(1.5)';
      //
      for (var i = 0; i < items.length; i++) {
        (function (i) {
          items[i].style['animation-delay'] = (-5) - 4 * i + 's,' + (-4 * i) + 's,' + (-4 * i) + 's'
        })(i);
      }
    },
  }
</script>

<style scoped>
  @media screen and (min-width:768px) and (max-width:991px){
    .container{
      display: none;
    }
  }
  @media screen and (max-width:767px){
    .container{
      display: none;
    }
  }
  /*第一层*/
  @keyframes animX{
    0% {
      left: 0px;
    }
    100% {
      left: 500px;
    }
  }
  @keyframes animY{
    0% {
      top: 0px;
    }
    100% {
      top: 300px;
    }
  }
  @keyframes scale {

    0% {
      transform: scale(0.4);
      z-index: 0
    }
    50% {
      transform: scale(1);
      z-index: 3
    }
    100% {
      transform: scale(0.5);
      z-index: 0
    }
  }


  .ball {
    width: 105px;
    height: 105px;
    /*background-color: #f66;*/
    border-radius: 50%;
    position: absolute;
    color:#fff;
    font-size:22px;
    display:flex;
    align-items:center;
    justify-content:center;
    /* background: yellow; */
    background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    line-height: 120px;
    z-index: 3;
    /*动画执行一次所需时间*/
    animation-duration: 10s, 10s, 20s;
    /*动画以何种运行轨迹完成一个周期*/
    animation-timing-function: cubic-bezier(0.36,0,0.64,1);
    /*动画播放次数,无线循环播放*/
    animation-iteration-count: infinite;
    /*是否轮流反向播放动画 alternate:正向播放*/
    animation-direction: alternate;
    animation-name: animX, animY,scale;
  }
  .borther :nth-child(2){
    background: #409EFF;
  }
  .borther :nth-child(3){
    background: #67C23A;
  }
  .borther :nth-child(4){
    background: #ff6600;
  }
  .borther :nth-child(5){
    background: #ff0033;
  }
  .borther :nth-child(6){
    background: #909399;
  }
  .borther2 :nth-child(2){
    background: #66cccc;
  }
  .borther2 :nth-child(3){
    background: #ff99cc;
  }
  .borther2 :nth-child(4){
    background: #666699;
  }
  .borther2 :nth-child(5){
    background: #ccff00;
  }
  .borther2 :nth-child(6){
    background: #ffcc00;
  }
  #lopp {
    width: 500px;
    height:300px;
    border: 2px solid #999;
    border-radius: 50%;
    position: absolute;
    left: 50px;
    top: 70px;
  }
  /*鼠标进入停止*/
  .active {
    animation-play-state: paused;
  }
  /*第二层*/
  @keyframes anim2X{
    0% {
      left: 100px;
    }
    100% {
      left: 420px;
    }
  }
  @keyframes anim2Y{
    0% {
      top: 70px;
    }
    100% {
      top: 200px;
    }
  }
  .ball2 {
    z-index: 3;
    width: 88px;
    height: 88px;
    /*background-color: #f66;*/
    border-radius: 50%;
    position: absolute;
    color:#fff;
    font-size:22px;
    display:flex;
    align-items:center;
    justify-content:center;
    background: red;
    background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    /*line-height: 120px;*/
    /*动画执行一次所需时间*/
    animation-duration: 10s, 10s, 20s;
    /*动画以何种运行轨迹完成一个周期*/
    animation-timing-function: cubic-bezier(0.36,0,0.64,1);
    /*动画播放次数,无线循环播放*/
    animation-iteration-count: infinite;
    /*是否轮流反向播放动画 alternate:正向播放*/
    animation-direction: alternate;
    animation-name: anim2X, anim2Y,scale;
  }
  #lopp2 {
    width: 300px;
    height: 150px;
    border: 2px solid #999;
    border-radius: 50%;
    position: absolute;
    left: 150px;
    top: 120px;
  }
  .circle {
    position: absolute;
    top: 114px;
    left: 250px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    line-height:100px;
    z-index: 3;
    color: #fff;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    background: #0aa1ed;
    background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
  }
  .container {
    font-family: "Microsoft YaHei";
    position: relative;
    transform: rotateX(20deg);
      width: 630px;
      height: 450px;
      margin: 0 auto;
    }
</style>