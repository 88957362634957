import VueRouter from "vue-router";

import Home from "../pages/Home"
import Login from "../pages/Login"
import Absent from "../pages/Absent"

const router = new VueRouter({
  mode:"history",
  routes:[
    {
      path:"/",
      component:Home,
      meta:{
        title:"朱利强的个人网站"
      },
    },
    {
      path:"/login",
      component:Login,
      meta:{
        title:"登录"
      },
    },
    {
      path:"*",
      component:Absent,
      meta:{
        title:"404，访问的页面不存在"
      },
    },
  ],
});

router.afterEach((to,from) => {
  document.title = to.meta.title
});

export default router