<template>
    <div class="leave_message ">
      <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="14">
          <div class="rty ">
            <div class="container">
              <div id="captcha"></div>
              <div id="msg"></div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="10">
          <el-form :model="myForm" :rules="rules"  ref="myForm" label-width="60px" class="form_left ">
            <el-form-item label="姓名" prop="userName" class="fff">
              <el-input v-model="myForm.userName"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="userEmail">
              <el-input v-model="myForm.userEmail"></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="userMessage">
              <el-input type="textarea" v-model="myForm.userMessage"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('myForm')">留言</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24">
          <div class="footer">
            <p><a href="https://beian.miit.gov.cn/">鲁ICP备2024056311号-1</a></p>
            <p>Inspired by dandyweng.com</p>
            <p>Designed and developed by zhuliqiaqng</p>
            <p>Copyright © 2018-2024 zhuliqiang.com. All Rights Reserved</p>
          </div>
        </el-col>
      </el-row>
    </div>
</template>

<script>
  import axios from 'axios';
  export default {
    name:"LeaveMessage",
    data(){
      return{
        myForm:{
          userName:"",
          userEmail:"",
          userMessage:"",
        },
        rules:{
          userName:[
            { required: true, message: '请输入您的姓名', trigger: 'blur' },
            { min: 1, max: 9, message: '长度在 1 到 9 个字符', trigger: 'blur' }
          ],
          userEmail:[
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
          ],
          userMessage:[
          { required: true, message: '请输入留言的内容', trigger: 'blur' },
          { min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur' }
          ],
        },
      }
    },
    methods:{
      submitForm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(window.isVerify){
              let value = this.myForm;
              axios.post("/addUserMessage",value).then((res) => {
                if(res.data){
                  this.$message({
                    showClose: true,
                    message: '留言成功！',
                    type: 'success'
                  });
                }else{
                  this.$message({
                    showClose: true,
                    message: '留言失败，服务器错误，请稍后重试！',
                    type: 'error'
                  });
                }
              });
              this.$refs[formName].resetFields();
            }else{
              this.$message({
                showClose: true,
                message: '请完成验证！',
                type: 'error'
              });
            }
          } else {
            this.$message({
              showClose: true,
              message: '请完成验证！',
              type: 'error'
            });
            return false;
          }
        });
      },
    },
    mounted(){
      jigsaw.init({
      el: document.getElementById('captcha'),
      onSuccess: function() {
      document.getElementById('msg').innerHTML = '验证成功！'
      window.isVerify = true;
    },
    onFail: cleanMsg,
    onRefresh: cleanMsg
  })
  function cleanMsg() {
    document.getElementById('msg').innerHTML = ''
  }
    },
  }
</script>

<style lang="less">
  @media screen and (max-width:767px){
    .footer{
      text-align: left !important;
      p{
        a{
          margin-left: 0 !important;
        }
      }
    }
  }
    .container {
      width: 310px;
      margin:5px 0 0 30px;
    }
    @media (max-width: 1200px){
      .container {
        margin:5px 0 0 0;
        }
      }
    input {
      display: block;
      width: 290px;
      line-height: 40px;
      margin: 10px 0;
      padding: 0 10px;
      outline: none;
      border:1px solid #c8cccf;
      border-radius: 4px;
      color:#6a6f77;
    }
    #msg {
      width: 100%;
      line-height: 40px;
      font-size: 14px;
      text-align: center;
    }
    a:link,a:visited,a:hover,a:active {
      margin-left: 100px;
      color: #0366D6;
    }
    // 验证码结束
  .leave_message{
    .footer{
      text-align: right;
      p{
        color:rgb(95, 95, 95);
        line-height:22px;
        a{
          color:rgb(95, 95, 95);
          line-height:22px;
          font-size:16px;
        }
      }
    }
    .form_left{
      margin-left:10px;
      margin-bottom:40px;
    }
    color:#fdfdfd;
    max-width:800px;
    .el-form-item__label{
      font-weight: 600;
      color:#fff;
    }
  }
  @media (min-width: 1800px){
    .leave_message {
          margin-left: 30px;
      }
    }
</style>